import * as User from "Everlaw/User";
import * as Perm from "Everlaw/PermissionStrings";
import * as Project from "Everlaw/Project";

export interface Permissions {
    canDbAdmin: boolean;
    canAdminLegalHolds: boolean;
    canProjectAdmin: boolean;
    canAdminAGs: boolean;
    canCreateAGs: boolean;
    canReceiveAGs: boolean;
    canAdminSTRs: boolean;
    canCreateSTRs: boolean;
    canReceiveSTRs: boolean;
    canAnalytics: boolean;
    canAdminPC: boolean;
    canCreatePC: boolean;
    canReceivePC: boolean;
    canUpload: boolean;
    canAdminProductions: boolean;
    canShareProductions: boolean;
    canAdminSB: boolean;
    canCreateSB: boolean;
    canReceiveSB: boolean;
    canReadClustering: boolean;
    canAdminClustering: boolean;
    canCodesAdmin: boolean;
    canUnitize: boolean;
}

export function checkPermissions(): Permissions {
    if (!User.me) {
        // Since Permissions is an Object with a bunch of boolean properties, checking a permission
        // on this object will just return undefined, which will be correctly interpreted as false.
        return {} as Permissions;
    }
    // Avoid as many permission checks as possible here by using known dependency logic to short-
    // circuit wherever possible.
    const canDbAdmin = User.me.can(
        Perm.DB_ADMIN,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    const canAdminLegalHolds =
        canDbAdmin
        || User.me.can(Perm.DB_LEGAL_HOLD, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);

    const canProjectAdmin = User.me.can(
        Perm.ADMIN,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    const canUnitize =
        canProjectAdmin
        || User.me.can(Perm.UNITIZATION, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);

    const canCodesAdmin = User.me.can(
        Perm.CODES_ADMIN,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );

    const canAdminAGs =
        canProjectAdmin
        || User.me.can(
            Perm.ADMIN_ASSIGNMENT_GROUPS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canCreateAGs =
        canAdminAGs
        || User.me.can(
            Perm.CREATE_ASSIGNMENT_GROUPS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canReceiveAGs =
        canCreateAGs
        || User.me.can(
            Perm.RECEIVE_ASSIGNMENT_GROUPS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );

    const canAdminSTRs =
        canProjectAdmin
        || User.me.can(Perm.ADMIN_STRS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
    const canCreateSTRs =
        canAdminSTRs
        || User.me.can(Perm.CREATE_STRS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);
    const canReceiveSTRs =
        canCreateSTRs
        || User.me.can(Perm.RECEIVE_STRS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);

    const canAnalytics =
        canProjectAdmin
        || User.me.can(Perm.ANALYTICS, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);

    const canAdminPC =
        canProjectAdmin
        || User.me.can(
            Perm.ADMIN_PREDICTION_MODELS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canCreatePC =
        canAdminPC
        || User.me.can(
            Perm.CREATE_PREDICTION_MODELS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canReceivePC =
        canCreatePC
        || User.me.can(
            Perm.RECEIVE_PREDICTION_MODELS,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );

    const canUpload = User.canUploadToCurrentProject(User.Override.ELEVATED_OR_ORGADMIN);

    const canAdminProductions =
        Project.CURRENT?.productionMode
        && (canProjectAdmin
            || User.me.can(
                Perm.ADMIN_PRODUCTIONS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            ));
    const canShareProductions =
        Project.CURRENT?.productionMode
        && (canAdminProductions
            || User.me.can(
                Perm.SHARE_PRODUCTIONS,
                Project.CURRENT,
                User.Override.ELEVATED_OR_ORGADMIN,
            ));

    const canAdminSB =
        canProjectAdmin
        || User.me.can(
            Perm.ADMIN_STORYBUILDER,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canCreateSB =
        canAdminSB
        || User.me.can(
            Perm.CREATE_STORYBUILDER,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    const canReceiveSB =
        canCreateSB
        || User.me.can(
            Perm.RECEIVE_STORYBUILDER,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );

    const canAdminClustering = User.me.can(
        Perm.ADMIN_CLUSTERING,
        Project.CURRENT,
        User.Override.ELEVATED_OR_ORGADMIN,
    );
    const canReadClustering =
        canAdminClustering
        || User.me.can(Perm.READ_CLUSTERING, Project.CURRENT, User.Override.ELEVATED_OR_ORGADMIN);

    return {
        canDbAdmin,
        canAdminLegalHolds,
        canProjectAdmin,
        canAdminAGs,
        canCreateAGs,
        canReceiveAGs,
        canAdminSTRs,
        canCreateSTRs,
        canReceiveSTRs,
        canAnalytics,
        canAdminPC,
        canCreatePC,
        canReceivePC,
        canUpload,
        canAdminProductions,
        canShareProductions,
        canAdminSB,
        canCreateSB,
        canReceiveSB,
        canReadClustering,
        canAdminClustering,
        canCodesAdmin,
        canUnitize,
    };
}

export function shouldDisableAdminIcons(perms: Permissions) {
    return !perms.canProjectAdmin || displayProjectAsSuspended();
}

export function shouldDisableCodesAdminIcons(perms: Permissions) {
    return !perms.canCodesAdmin || displayProjectAsSuspended();
}

export function shouldDisableAssignmentGroupsIcon(perms: Permissions) {
    return !perms.canReceiveAGs || displayProjectAsSuspended();
}

export function shouldDisableAnalyticsIcon(perms: Permissions) {
    return !perms.canAnalytics || displayProjectAsSuspended();
}

export function shouldDisableClusteringIcon(perms: Permissions) {
    return !perms.canReadClustering || displayProjectAsSuspended();
}

export function shouldDisablePredictiveCodingIcon(perms: Permissions, pcTab: string | null) {
    return !(pcTab && perms.canReceivePC) || displayProjectAsSuspended();
}

export function shouldDisableSTRIcon(perms: Permissions) {
    return !perms.canReceiveSTRs || displayProjectAsSuspended();
}

export function shouldDisableLegalHoldsIcon(perms: Permissions) {
    return !perms.canAdminLegalHolds || displayProjectAsSuspended();
}

export function shouldDisableProductionsIcon(perms: Permissions) {
    return !perms.canShareProductions || displayProjectAsSuspended();
}

export function shouldDisableUploadsIcon(perms: Permissions) {
    return !perms.canUpload || displayProjectAsSuspended();
}

export function displayProjectAsSuspended() {
    return Project.CURRENT.suspended && !User.me.hasElevatedRole();
}
